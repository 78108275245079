import { render, staticRenderFns } from "./trip-view-card.vue?vue&type=template&id=19c1153c&scoped=true&"
import script from "./trip-view-card.vue?vue&type=script&lang=js&"
export * from "./trip-view-card.vue?vue&type=script&lang=js&"
import style0 from "./trip-view-card.vue?vue&type=style&index=0&id=19c1153c&lang=scss&scoped=true&"
import style1 from "./trip-view-card.vue?vue&type=style&index=1&id=19c1153c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c1153c",
  null
  
)

export default component.exports
import {QSpinnerIos,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSpinnerIos,QIcon})
