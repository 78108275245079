import { render, staticRenderFns } from "./trip-cancellation-modal.vue?vue&type=template&id=dc39b304&scoped=true&"
import script from "./trip-cancellation-modal.vue?vue&type=script&lang=js&"
export * from "./trip-cancellation-modal.vue?vue&type=script&lang=js&"
import style0 from "./trip-cancellation-modal.vue?vue&type=style&index=0&id=dc39b304&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc39b304",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QForm,QInput,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QForm,QInput,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
