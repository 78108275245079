//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import i18n from '@/vueI18n';
import moment from 'moment';
import TripViewCard from '@/modules/trip/components/trip-view-card.vue';
import TripCancellationModal from '@/modules/trip/components/trip-cancellation-modal.vue';
import TripViewReservationTable from '@/modules/trip/components/trip-view-reservation-table.vue';

export default {
  data() {
    return {
        modalVisible: false,
        dialogVisible: false
    };
  },
  methods: {
    ...mapActions({
      doFind: 'trip/view/doFind',
      doStartTrip: 'trip/view/doStartTrip',
    }),
    onOpenModal(){
        this.modalVisible=true;
        this.dialogVisible=true;
    },
    closeModal(){
        setTimeout(() => {
        this.modalVisible = false;
      }, 200);
        this.dialogVisible=false;
    },
    async startTrip() {
      await this.doStartTrip(this.record.id);
      if(this.response.status==true){
          this.record.status='started'
      }
      this.showNotification(this.response.status);
    },
    showNotification(status) {
      if (status == false) {
        this.$q.notify({
          color: 'red',
          icon: 'report_problem',
          message: this.response.error.message,
          position:'top-right'
        });
      }
     else {
        this.$q.notify({
          color: 'green',
          message: this.response.result,
          position:'top-right'
        });
      }
    },
    presenterName(value) {
      return value[this.language];
    },
    presenterDate(date) {
      return moment(date)
        .locale(this.language)
        .format('D MMMM');
    },
    presenterDay(date) {
      return moment(date)
        .locale(this.language)
        .format('dddd');
    },
    presenterTime(date) {
      return moment(date)
        .locale(this.language)
        .format('hh:mm a');
    },
    presenterCapacity() {
      return (
        this.record.capacity.min +
        '-' +
        this.record.capacity.max
      );
    },
  },
  components: {
    [TripViewCard.name]: TripViewCard,
    [TripViewReservationTable.name]: TripViewReservationTable,
    [TripCancellationModal.name]: TripCancellationModal
  },
  computed: {
    ...mapGetters({
      record: 'trip/view/record',
      response: 'trip/view/response',
    }),
    language() {
      return i18n.locale;
    },
  },
  async created() {
    await this.doFind({
      id: this.$route.params.id,
      includeCount: true,
    });
    // await this.doFind({id: "1ajMs6bl3TjgxP6hPrgK", includeCount: true})
    // console.log(this.record);
    // debugger
    // console.log(this.record.reservation);
    // console.log(this.record.guides);
    // debugger
  },
};
