import { render, staticRenderFns } from "./trip-view-reservation-table.vue?vue&type=template&id=2959aefb&"
import script from "./trip-view-reservation-table.vue?vue&type=script&lang=js&"
export * from "./trip-view-reservation-table.vue?vue&type=script&lang=js&"
import style0 from "./trip-view-reservation-table.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./trip-view-reservation-table.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable,QTr,QTd,QAvatar,QBtn,QTooltip,QIcon,QInnerLoading,QSpinnerIos} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTr,QTd,QAvatar,QBtn,QTooltip,QIcon,QInnerLoading,QSpinnerIos})
