//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-trip-cancellation-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
  },
  async mounted(){
    await this.doFetchCancellationFees({id:this.trip.id, type: 'trip'})
  },

  data() {
    return {
      language: getLanguageCode(),
      model: null,
      cancellationReason:'',

    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      cancellationFees: 'trip/view/cancellationFee',
      cancellationResponse: 'trip/view/response',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
        doCancelTrip: 'trip/view/doCancelTrip',
        doFetchCancellationFees:'trip/view/doFetchCancellationFees'
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    async onSubmit(){
        console.log(this.complaint,this.trip.id);
        await this.doCancelTrip({id: this.trip.id,reason: this.cancellationReason})
        this.showNotification()
        this.$emit('close');
    },
    showNotification() {
      if (this.cancellationResponse.status == false) {
        this.$q.notify({
          color: 'red',
          icon: 'report_problem',
          message: this.cancellationResponse.error.message,
          position:'top-right'
        });
      }
     else {
        this.$q.notify({
          color: 'green',
          message: this.cancellationResponse.result,
          position:'top-right'
        });
      }
    },
  },
};
