//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import TripViewMembersModal from '@/modules/trip/components/trip-view-members-modal.vue';


export default {
    name: 'app-trip-view-reservation-table',
    data(){
        return{
            reservationsColumns: [
                            
                {
                    name: 'clientAvatar',
                    align: 'center',
                    label: this.i18n('entities.trip.view.clientAvatar'),
                    field: 'clientAvatar',
                }, 
                {
                    name: 'clientName',
                    align: 'center',
                    label: this.i18n('entities.trip.view.clientName'),
                    field: 'clientName',
                }, 
                {
                    name: 'clientPhoneNumber',
                    align: 'center',
                    label: this.i18n('entities.trip.view.clientPhoneNumber'),
                    field: 'clientPhoneNumber',
                },{
                    name: 'guide',
                    align: 'center',
                    label: this.i18n('entities.trip.view.guide'),
                    field: 'guide',
                }, 
                // {
                //     name: 'guideAvatar',
                //     align: 'center',
                //     label: this.i18n('entities.trip.view.guideAvatar'),
                //     field: 'guideAvatar',
                // },
                {
                    name: 'arrived',
                    align: 'center',
                    label: this.i18n('entities.trip.view.arrived'),
                    field: 'arrived',
                },
                {
                    name: 'action',
                    align: 'center',
                    label: this.i18n('entities.trip.view.action'),
                    field: 'action',
                },
            ],

            Pagination: {
                rowsPerPage: 10
            },

            modalVisible: false,
            dialogVisible: false
        }
    },
    components:{
        [TripViewMembersModal.name]: TripViewMembersModal
    },
    computed: {
        ...mapGetters({
            record: 'trip/view/record',
            loading: 'trip/view/loading',
            is_screen_xs: 'layout/is_screen_xs'
        })
    },
    methods:{
        matchGuideName(guideId){
           const guide = this.record.guides.filter(el => el.id == guideId)[0]
           return guide.fullName
        },
        matchGuideAvatar(guideId){
           const guide = this.record.guides.filter(el => el.id == guideId)[0]
           return guide.avatar.publicUrl
        },
        i18n(key, args) {
            // return i18n(key, args);
            return this.$t(key, args);
        },
        onOpenModal() {
            this.modalVisible = true;
            this.dialogVisible = true;
        },
        onModalClose() {
            this.dialogVisible = false;
            setTimeout(() => {
                this.modalVisible = false;
            }, 200);
        },
    },
    created(){
        // console.log();
        // this.matchGuideName(this.record.reservations[0].tourGuideId)
        // debugger
    }

}
